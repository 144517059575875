import { useLocation } from '@reach/router';
import { Container, Pagination } from 'components';
import { SEO } from 'components/SEO';
import { PageProps } from 'gatsby';
import { Product, SeoDescriptions } from 'models';
import { Breadcrumbs, setFitlerUrl } from 'modules/navigation';
import {
  DesktopModelPageFilters,
  filterByColor,
  filterByConcept,
  filterByConsumption,
  filterByPcsPerSqMeter,
  filterByRoofPitch,
  filterByWallWidth,
  MobileModelPageFilters,
  ProductListing,
  sortAlphabetically,
  usePagination,
} from 'modules/products';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { listing } from 'styles';
import { createMarkup, localizeNavCategory, notEmpty } from 'utils';

interface Props {
  products: Product[];
  navCategory: 'Roof' | 'Wall' | 'Facade';
  modelDescription?: SeoDescriptions;
  metaDescription?: string;
}

export interface ModelFiltersState {
  consumptions: {
    [key: string]: boolean;
  };
  colors: {
    [key: string]: boolean;
  };
  minRoofPitch: {
    [key: string]: boolean;
  };
  wallWidths: {
    [key: string]: boolean;
  };
  piecesPerMsq: {
    [key: string]: boolean;
  };
  dimensions: {
    [key: string]: boolean;
  };
  concepts: {
    [key: string]: boolean;
  };
}

const INITIAL_FILTER_STATE = {
  consumptions: {},
  colors: {},
  minRoofPitch: {},
  wallWidths: {},
  piecesPerMsq: {},
  dimensions: {},
  concepts: {},
};

const ModelPage: React.FC<PageProps<unknown, Props>> = ({
  pageContext: { products, navCategory, modelDescription, metaDescription },
}) => {
  const location = useLocation();
  const queryParse = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [state, setState] = useState<ModelFiltersState>(INITIAL_FILTER_STATE);
  const [filteredProducts, setFilteredProducts] = useState([...products]);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const {
    p: currentLocationPage = 1,
    utrosak,
    boja,
    nagib,
    debljinaZida,
    m2,
    dimenzija,
    koncept,
  } = queryParse;

  const isCommercialBrandExisting =
    products[0] && products[0].commercial?.brand;
  const pageTitle = isCommercialBrandExisting
    ? `${products[0].commercial.brand} ${products[0].commercial.model}`
    : products[0].commercial.model;

  const { next, prev, jump, currentData, currentPage, maxPage } =
    usePagination<Product>(
      filteredProducts,
      itemsPerPage,
      Number(currentLocationPage),
    );

  const concepts = [
    ...new Set(products.map((product) => product.commercial.concept)),
  ].filter(notEmpty);
  const colors = [
    ...new Set(products.map((product) => product.commercial.corporateColor)),
  ].filter(notEmpty);
  const wallWidths = [
    ...new Set(
      products.map((product) => product?.sku?.dimensions?.width || null),
    ),
  ]
    .filter(notEmpty)
    .sort((a, b) => a - b);
  const consumptions = [
    ...new Set(
      products.map(
        (product) =>
          product?.sku?.salesRelation?.piecesDry ||
          product?.sku?.salesRelation?.piecesMortar ||
          null,
      ),
    ),
  ]
    .filter(notEmpty)
    .sort((a, b) => a - b);
  const dimensions = [
    ...new Set(
      products.map((product) => {
        if (!product.sku?.dimensions) return;

        const { length, width, height } = product.sku.dimensions;
        return `${length}mm x ${width}mm x ${height}mm`;
      }),
    ),
  ].filter(notEmpty);

  useEffect(() => {
    const filteredProductsByPcsPerSqMeter = filterByPcsPerSqMeter(
      state.piecesPerMsq,
      products,
    );

    const filteredColorProducts = filterByColor(
      state.colors,
      filteredProductsByPcsPerSqMeter,
    );

    const filteredProductsByRoofPitch = filterByRoofPitch(
      state.minRoofPitch,
      filteredColorProducts,
    );

    const filteredWidthProducts = filterByWallWidth(
      state.wallWidths,
      filteredProductsByRoofPitch,
    );

    const filteredConsumptionProducts = filterByConsumption(
      state.consumptions,
      filteredWidthProducts,
    );

    const filteredConceptProducts = filterByConcept(
      state.concepts,
      filteredConsumptionProducts,
    );

    const sortedProducts = sortAlphabetically<Product>(filteredConceptProducts);

    setFilteredProducts(sortedProducts);
  }, [state, products, location.pathname]);

  useEffect(() => {
    if (utrosak) {
      const consumptionsString = utrosak as string;
      const consumptions = consumptionsString.split('-');

      consumptions.forEach((consumption: string) => {
        handleChange(consumption, true, 'consumptions');
      });
    }

    if (boja) {
      const colorString = boja as string;
      const colors = colorString.split('-');

      colors.forEach((color: string) => {
        handleChange(color, true, 'colors');
      });
    }

    if (nagib) {
      const pitchesString = nagib as string;
      const pitches = pitchesString.split('-');

      pitches.forEach((pitch: string) => {
        handleChange(pitch, true, 'minRoofPitch');
      });
    }

    if (m2) {
      const metersString = m2 as string;
      const meters = metersString.split('-');

      meters.forEach((meter: string) => {
        handleChange(meter, true, 'piecesPerMsq');
      });
    }

    if (debljinaZida) {
      const wallWidths = debljinaZida as string;
      const widths = wallWidths.split('-');

      widths.forEach((width: string) => {
        handleChange(width, true, 'wallWidths');
      });
    }

    if (dimenzija) {
      const dimensionsString = dimenzija as string;
      const dimensions = dimensionsString.split('-');

      dimensions.forEach((dimension: string) => {
        handleChange(dimension, true, 'wallWidths');
      });
    }

    if (koncept) {
      const conceptString = koncept as string;
      const concepts = conceptString.split('-');

      concepts.forEach((concept: string) => {
        handleChange(concept, true, 'concepts');
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boja, m2, dimenzija, koncept, nagib, utrosak, debljinaZida]);

  useEffect(() => {
    setFitlerUrl(state, currentPage);
  }, [
    currentPage,
    state,
    state.colors,
    state.consumptions,
    state.minRoofPitch,
    state.dimensions,
    state.concepts,
    state.piecesPerMsq,
    state.wallWidths,
  ]);

  if (!products.length) return null;

  function getCategorySlug() {
    if (products[0].commercial.category === 'Krovni prozori oprema')
      return 'Tondach';
    return products[0].commercial.category || products[0].commercial.brand;
  }

  return (
    <Container>
      <SEO
        productName={getSEOTitle() + products[0].commercial.model}
        productDescription={metaDescription}
      />
      <Breadcrumbs
        navigationCategory={localizeNavCategory(
          products[0].commercial.navigationCategories.code,
        )}
        category={getCategorySlug()}
        model={products[0].commercial.model}
      />

      <div css={listing.flex}>
        <DesktopModelPageFilters
          products={products}
          navCategory={navCategory}
          dimensions={dimensions}
          concepts={concepts}
          colors={colors}
          wallWidths={wallWidths}
          consumptions={consumptions}
          handleChange={handleChange}
          clearFilters={clearFilters}
          filtersState={state}
        />

        <section css={listing.listSection}>
          <MobileModelPageFilters
            products={products}
            navCategory={navCategory}
            dimensions={dimensions}
            concepts={concepts}
            colors={colors}
            wallWidths={wallWidths}
            consumptions={consumptions}
            handleChange={handleChange}
            clearFilters={clearFilters}
            filtersState={state}
          />

          {modelDescription && (
            <div css={listing.descriptionWrapper}>
              <h2 css={listing.pageTitle}>{pageTitle}</h2>
              <div css={listing.descriptionContent}>
                <p
                  dangerouslySetInnerHTML={createMarkup(
                    modelDescription.description,
                  )}
                  css={listing.pageDescription}
                />
                <ul css={listing.bulletsWrapper}>
                  {modelDescription.bullets?.map((bullet) => (
                    <li key={bullet} css={listing.pageDescription}>
                      {bullet}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <ProductListing products={currentData} />

          {filteredProducts.length > 10 && (
            <Pagination
              currentPage={Number(currentLocationPage)}
              next={next}
              prev={prev}
              jump={jump}
              maxPage={maxPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
            />
          )}
        </section>
      </div>
    </Container>
  );

  function handleChange(name: string, value: boolean, section?: string) {
    if (!section) return;

    setState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [name]: value,
      },
    }));
  }

  function clearFilters() {
    setState(INITIAL_FILTER_STATE);
    jump(1);
  }

  function getSEOTitle() {
    switch (navCategory) {
      case 'Wall':
        return 'Porotherm opeka ';
      case 'Facade':
        return 'Terca fasdana opeka ';
      case 'Roof':
        return 'Tondach crijep ';
      default:
        return '';
    }
  }
};

export default ModelPage;
